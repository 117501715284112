<!-- eslint-disable vue/valid-v-for -->
<template>
  <CBox>
    <CBox
      mt="50px"
      bg="white"
      minH="100px"
      borderRadius="5px"
      boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
    >
      <CBox
        py="20px"
        mx="20px"
        justifyContent="space-between"
        display="flex"
        alignItems="center"
      >
        <CText
          fontFamily="Montserrat"
          fontStyle="normal"
          fontSize="18px"
          fontWeight="800"
          color="fontMain"
        >
          Logic Result - Detail Logs
        </CText>

        <CBox textAlign="right" my="auto">
          <CButton
            variant="outline"
            backgroundColor="#fff"
            mr="2"
            color="main"
            borderColor="main"
            fontSize="12px"
            @click="copyClipboard()"
          >
            <CImage
              :src="require('@/assets/icon/copy.svg')"
              w="12px"
              h="12px"
              mr="2"
            />
            Copy URL
          </CButton>
        </CBox>
      </CBox>
      <CDivider mt="0" />
      <CBox display="flex" justifyContent="center" v-if="loading">
        <CSpinner />
      </CBox>

      <CBox
        display="flex"
        justifyContent="center"
        fontWeight="700"
        mt="15px"
        fontSize="15px"
        v-if="!loading && !data.headerData"
      >
        Result Not Found
      </CBox>

      <CStack spacing="1px" p="10px">
        <CBox
          v-for="(item, index) in data.headerData"
          :key="index"
          mx="20px"
          display="flex"
          justifyContent="flex-start"
        >
          <CText
            fontFamily="Montserrat"
            fontStyle="normal"
            fontSize="15px"
            color="fontMain"
            fontWeight="700"
            mr="10px"
          >
            {{ item.label }} :
          </CText>

          <CText
            fontFamily="Montserrat"
            fontStyle="normal"
            fontSize="15px"
            color="fontMain"
            fontWeight="500"
          >
            {{ item.value }}
          </CText>
        </CBox>
      </CStack>
    </CBox>

    <CStack spacing="20px" p="10px" mt="20px">
      <CBox
        v-for="(item, index) in data.solutions"
        :key="index"
        p="20px"
        borderRadius="5px"
        bg="white"
        minH="100px"
        boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
      >
        <CBox display="flex" justifyContent="space-between" mb="10px">
          <CText
            fontFamily="Montserrat"
            fontStyle="normal"
            fontSize="17px"
            color="fontMain"
            fontWeight="600"
            mr="10px"
          >
            STATUS: {{ renderStatus(item?.solution) }}
          </CText>
          <CText
            fontFamily="Montserrat"
            fontStyle="normal"
            fontSize="17px"
            color="fontMain"
            fontWeight="600"
            mr="10px"
          >
            {{ formatDate(item?.solution?.created_at) }}
          </CText>
        </CBox>

        <CText
          fontFamily="Montserrat"
          fontStyle="normal"
          fontSize="16px"
          color="fontMain"
          fontWeight="700"
          mr="10px"
        >
          {{ item.title }}
        </CText>

        <CBox
          class="description"
          fontFamily="Montserrat"
          fontStyle="normal"
          color="gray"
          fontSize="14px"
          my="10px"
          p="25px"
          maxHeight="300px"
          overflow="auto"
          v-html="compiledMarkdown(item.description)"
        ></CBox>

        <VueCodeHighlight language="javascript">
          <code>
            {{ item.solution?.code ?? "// user has no answer" }}
          </code>
        </VueCodeHighlight>
      </CBox>
    </CStack>
  </CBox>
</template>

<script>
import ApiService from "@/store/api.service";
import moment from "moment-timezone";
import { component as VueCodeHighlight } from "vue-code-highlight";
import marked from "marked";
import "vue-code-highlight/themes/duotone-sea.css";
import "vue-code-highlight/themes/window.css";

export default {
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  components: {
    VueCodeHighlight,
  },
  data() {
    return {
      loading: false,
      data: {},
      defaultTimezone: "Asia/Jakarta",
    };
  },
  computed: {
    compiledMarkdown() {
      return (markdown) => marked(markdown, { sanitize: true });
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      try {
        this.loading = true;
        const id = this.$route.params.id;

        const {
          data: { data },
        } = await ApiService.get(`/logic-test/detail-log/${id}`, {
          appTypeId: this.id,
        });

        console.log("data:", JSON.parse(JSON.stringify(data.items)));
        const headerData = this.mappingHeaderData(data);

        this.data = { headerData, solutions: data?.items };
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    mappingHeaderData(data) {
      return [
        {
          label: "Result ID",
          value: data?.result_id,
        },
        {
          label: "Full Name",
          value: data?.full_name,
        },
        {
          label: "Email",
          value: data?.email,
        },
        {
          label: "Challenge Name",
          value: data?.challenge_name,
        },
        {
          label: "Start Test",
          value: this.formatDate(data?.start_test),
        },
        {
          label: "End Date",
          value: this.formatDate(data?.end_date),
        },
        {
          label: "Total Questions",
          value: data?.total_questions,
        },
      ];
    },
    copyClipboard() {
      var tempInput = document.createElement("input");
      tempInput.style = "position: absolute; left: -1000px; top: -1000px";
      tempInput.value = window.location;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
      this.$toast({
        title: "Copied",
        status: "success",
        duration: 3000,
        position: "top",
        isClosable: false,
      });
    },
    formatDate(date) {
      return date
        ? moment(date)
            .tz(this.defaultTimezone)
            .format("DD MMMM YYYY [at] h:mm A")
        : "";
    },
    renderStatus(solution) {
      if (!solution) {
        return "FAILED";
      }

      return solution.status;
    },
  },
};
</script>
